import * as R from 'ramda'
import { format, addSeconds, fromUnixTime } from 'date-fns'
import createThemeCSS from '@/utils/createThemeCSS'
import { formatFamilyMemberResponse } from '@/utils/familyMemberFormatters'
import fetchApi from '@/utils/fetchApi'
import * as restAPI from '@/utils/endpoints'
import * as React from "react";
import { isEmpty } from 'ramda'
import {
	GET_MAGIC_LINK_EMPLOYER_MASQUERADE,
	GET_SELECTIONS_FOR_APPROVAL,
	GET_TAKEUP_FOR_BENEFIT
} from "@/utils/endpoints";
import * as m from '@/utils/messages'
import { MTB_VIEW_MODE, SORTING_DIRECTIONS } from '@/utils/constants'
import 'url-search-params-polyfill';
import { navigate } from 'gatsby'
import { getDeviceToken } from "@/utils/EmployeeData/DeviceToken";

const mockAPI = () =>
	new Promise(resolve => {
		setTimeout(() => {
			resolve({
				primary: '#00C788',
				accent: '#FA713E',
				logo: '/logos/cloud8-logo.png'
			})
		}, 1000)
	})

// check SET_THEME isn't given null values before setting the state
const validateThemeData = (payload) => {
	let {
		primary,
		accent,
		// eslint-disable-next-line prefer-const
		clientLogo,
		benefitBrandName,
		brandHeaderLogo,
		supportEmail,
		supportNumber,
		// eslint-disable-next-line prefer-const
		avatar,
		brandHeaderLogoMobile,
		baseFont,
		headerFont
	} = payload

	// set defaults from the .env for the below where any are null
	// eslint-disable-next-line no-undef
	!primary && (primary = PRIMARY_COLOR);
	// eslint-disable-next-line no-undef
	!accent && (accent = SECONDARY_COLOR);
	!supportNumber && (supportNumber = CONTACT_NUMBER);
	!supportEmail && (supportEmail = SUPPORT_EMAIL_ADDRESS);
	!brandHeaderLogo && (brandHeaderLogo = LOGO);
	!benefitBrandName && (benefitBrandName = PROJECT_NAME);
	!brandHeaderLogoMobile && (brandHeaderLogoMobile = LOGO_MOBILE);

	if (brandHeaderLogo != LOGO && !brandHeaderLogoMobile)
		brandHeaderLogoMobile = brandHeaderLogo

	return {
		primary,
		accent,
		clientLogo,
		benefitBrandName,
		brandHeaderLogo,
		brandHeaderLogoMobile,
		supportEmail,
		supportNumber,
		avatar,
		baseFont,
		headerFont
	}
}

const buildThemePayload = (schemeDataObject = null) => {
	if (schemeDataObject) {
		const {
			theme,
			logoFileUrl,
			brandHeaderLogo,
			supportEmail,
			benefitBrandName,
			benefitBrandPhoneNumber,
			avatarsetPlainFileURL,
			mobileLogo,
			baseFont,
			headerFont
		} = schemeDataObject

		return {
			primary: `#${theme.primaryColour}`,
			accent: `#${theme.secondaryColour}`,
			benefitBrandName,
			brandHeaderLogo,
			clientLogo: logoFileUrl,
			supportEmail,
			supportNumber: benefitBrandPhoneNumber,
			avatar: avatarsetPlainFileURL,
			brandHeaderLogoMobile: mobileLogo,
			baseFont,
			headerFont
		}
	}
	return {
		theme: {
			primaryColour: `${PRIMARY_COLOR.replace("#", "")}`, // strip the hash off the hex colours from the .env file. not great, but probably the most painless wait to do it.
			secondaryColour: `${SECONDARY_COLOR.replace("#", "")}`,
		},
		benefitBrandName: PROJECT_NAME,
		brandHeaderLogo: LOGO,
		clientLogo: null,
		benefitBrandPhoneNumber: CONTACT_NUMBER,
		supportEmail: SUPPORT_EMAIL_ADDRESS,
		avatar: null,
		brandHeaderLogoMobile: LOGO_MOBILE,
		baseFont: null,
		headerFont: null
	}
}



const initialState = {
	loggedIn: false,
	loginError: false,

	accessToken: null,
	accessTokenExpires: null,
	refreshToken: null,

	benefitBrandName: PROJECT_NAME,

	passwordChanged: false,

	familyStatus: false,
	termsRevision: false,
	remember: false,
	email: '',
	lifeEvents: {
		user: false,
		available: []
	},
	client: {},
	family: [],
	payroll: false,
	provider: false,
	carousel: false,
	mtbSummary: {},
	monthlyBenefitsSummary: {},
	monthlyCompanyCosts: {},
	monthlyEmployeeCosts: {},

	masquerade: {},

	cmsData: {
		'hide-totals': false
	},

	employeeData: {
		totalRegisteredEmployees: undefined,
		totalEmployees: undefined,
		percentageRegistered: undefined,
		lastPage: 1,
		currentPage: 1,
		searchString: undefined,
		currentTotal: undefined,
		currentFilter: null,
		sortBy: undefined,
		range: SORTING_DIRECTIONS.Asc,
		perPage: 10,
		reportFile: undefined,
		filters: [],
		listOfEmployeesForClient: undefined
	},

	twoFAListData: {
		twoFAList: undefined,
		currentPage: 1,
		perPage: 10,
		currentTotal: undefined,
		currentFilter: null,
		reportFile: undefined,
		filters: [],
	},
	isEnforcementTwoFAEnabled: undefined,
	mfaData: {
		secret: undefined,
		url: undefined,
	},

	referenceSalary: {},
	cookiesAccepted: false,
	nonNecessaryCookiesEnabled: true,
	benefitTakeupData: {
		summaryData: false,
		detailedData: false
	},

	employmentStatus: false,
	approvalSelections: {
		count: 0
	},
	modeMTB: MTB_VIEW_MODE.YEAR,

}

const totalRegistered = employees => {
	let registered = 0
	for (let i = 0; i < employees.length; i++) {
		if (employees[i].accountStatus === "Registered") {
			registered++
		}
	}
	return registered
}

const calculatePercentage = (registered, total) => {
	// Percentage to one decimal place
	return Math.round(((registered / total) * 100) * 10) / 10
}

export default {
	state: initialState,
	reducers: {
		RESET(state) {

			localStorage.removeItem('client-theme');
			const loginError = state.loginError !== 'Please accept cookies before logging in' ? state.loginError : false

			return { ...initialState, loginError: loginError, cookiesAccepted: state.cookiesAccepted }
		},

		SET_LOGIN_CREDENTIALS(state, payload) {
			window.localStorage.setItem('loginCredentials', JSON.stringify(payload));
			return {
				...state,
				accessToken: payload.access_token,
				refreshToken: payload.refresh_token,
				accessTokenExpires: addSeconds(new Date(), payload.expires_in),
				ssoLogoutUrl: payload.ssoLogoutUrl
			}
		},

		SET_LOGIN(state, payload) {
			window.localStorage.setItem('loggedIn', true);
			return {
				...state,
				loggedIn: true,
				loginError: false,
				passwordChanged: false,
			}
		},

		SET_USER(state, payload) {
			window.localStorage.setItem('user', JSON.stringify(payload));

			return {
				...state,
				...payload
			}
		},

		SET_CLIENT(state, payload) {

			const result = []

			payload.clientExceptions.forEach(item => {
				result[item.code] = item.status === 'active'
			});

			return {
				...state,
				client: payload,
				cmsData: { ...state.cmsData, ...result }
			}

		},

		SET_CMS_DATA(state, payload) {

			const cmsData = [];

			payload.forEach(item => {
				cmsData[item.reference] = item.live;
			});

			return {
				...state,
				cmsData: { ...state.cmsData, ...cmsData }
			}
		},

		SET_FAMILY(state, payload) {
			const activeFamily = R.filter(familyMember => familyMember.active, payload)

			return {
				...state,
				family: activeFamily
			}
		},

		SET_LOGINV2(state, payload) {
			return {
				...state,
				loggedIn: true,
				loginError: false,
				passwordChanged: false,
				accessToken: payload.access_token,
				refreshToken: payload.refresh_token,
				accessTokenExpires: addSeconds(new Date(), payload.expires_in),
				...payload,
				family: R.compose(
					R.map(
						R.over(
							R.lensPath(['fullTimeEducationEndDate']),
							v => v && format(fromUnixTime(v.timestamp), 'Y-MM-dd')
						)
					),
					R.map(
						R.over(R.lensPath(['dateOfBirth']), ({ timestamp }) =>
							format(fromUnixTime(timestamp), 'Y-MM-dd')
						)
					),
					R.prop('family')
				)(payload)
			}
		},

		SET_LOGIN_ERROR(state, payload) {
			return {
				...state,
				loginError: payload
			}
		},

		SET_PASSWORD(state, payload) {
			return {
				...state,
				password: payload
			}
		},

		LOGOUT(state, payload) {
			window.localStorage.removeItem('loggedIn');
			window.localStorage.removeItem('user');
			window.localStorage.removeItem('loginCredentials');

			return {
				...state,
				loggedIn: false,
				loginError: payload,
				accessToken: null,
				refreshToken: null,
				accessTokenExpires: null,
				ssoLogoutUrl: null
			}
		},

		SET_REMEMBER_ME(state, payload) {
			return {
				...state,
				remember: payload
			}
		},

		SET_DETAILS(state, payload) {
			return {
				...state,
				...payload
			}
		},

		ADD_LIFE_EVENT(state, payload) {
			return {
				...state,
				events: [...state.events, payload]
			}
		},

		ADD_FAMILY_MEMBER(state, payload) {
			return {
				...state,
				family: [...state.family, payload]
			}
		},

		WELCOME_COMPLETE(state) {
			// eslint-disable-next-line no-param-reassign
			delete state.carousel
			return {
				...state
			}
		},

		SET_THEME(state, payload) {

			localStorage.setItem('client-theme', JSON.stringify(payload))

			const {
				primary,
				accent,
				clientLogo,
				benefitBrandName,
				brandHeaderLogo,
				brandHeaderLogoMobile,
				supportEmail,
				supportNumber,
				avatar,
				baseFont,
				headerFont
			} = validateThemeData(buildThemePayload(payload))

			return {
				...state,
				hasClientTheme: true,
				styles: createThemeCSS({ primary, accent, baseFont, headerFont }),
				primary,
				accent,
				clientLogo,
				benefitBrandName,
				brandHeaderLogo,
				brandHeaderLogoMobile,
				supportEmail,
				supportNumber,
				avatar
			}
		},

		SET_CAROUSEL_CONTENT(state, payload) {
			return {
				...state,
				carousel: payload
			}
		},

		SET_PAYROLL_REPORT(state, payload) {
			return {
				...state,
				payroll: (payload == 'Error') ? 'Error' : {
					changes: payload.changes,
					full: payload.full
				}
			}
		},

		SET_PROVIDER_REPORT(state, payload) {
			return {
				...state,
				provider: (payload == 'Error') ? 'Error' : {
					changes: payload.changes,
					full: payload.full
				}
			}
		},

		SET_SESSION_TIME(state, payload) {
			return {
				...state,
				sessionTime: payload
			}
		},

		ADD_FAMILY_STATUS(state, payload) {
			return {
				...state,
				familyStatus: payload
			}
		},

		SET_PASSWORD_CHANGED(state) {
			return {
				...state,
				passwordChanged: true
			}
		},

		SET_TERMS_REVISION(state, payload) {
			return {
				...state,
				termsRevision: payload.termsRevision,
				siteTermsUpdated: payload.siteTermsUpdated,
			}
		},

		SET_TOTAL_BENEFITS_SUMMARY(state, payload) {
			return {
				...state,
				mtbSummary: payload
			}
		},

		SET_MTB_PAGE_DATA(state, payload) {
			return {
				...state,
				mtb: {
					page: payload
				}
			}
		},

		SET_MONTHLY_COMPANY_COSTS(state, payload) {
			return {
				...state,
				monthlyCompanyCosts: payload
			}
		},

		SET_MONTHLY_EMPLOYEE_COSTS(state, payload) {
			return {
				...state,
				monthlyEmployeeCosts: payload
			}
		},

		SET_AVAILABLE_LIFE_EVENTS(state, payload) {
			return {
				...state,
				lifeEvents: {
					...state.lifeEvents,
					available: payload
				}
			}
		},

		SET_USER_LIFE_EVENTS(state, payload) {
			// family: [...state.family, payload]
			return {
				...state,
				lifeEvents: {
					...state.lifeEvents,
					user: payload
				}
			}
		},

		SET_CLIENT_EMPLOYEE_LIST(state, payload) {
			return {
				...state,
				employeeData: {
					...state.employeeData,
					listOfEmployeesForClient: payload?.data,
					totalEmployees: payload?.totalEmployeesValues?.totalEmployees,
					totalRegisteredEmployees: payload?.totalEmployeesValues?.totalRegisteredEmployees,
					lastPage: payload?.last_page,
					perPage: payload?.per_page,
					currentTotal: payload?.total,
					filters: payload?.filters,
					percentageRegistered: calculatePercentage(
						payload?.totalEmployeesValues?.totalRegisteredEmployees,
						payload?.totalEmployeesValues?.totalEmployees)
				}
			}
		},

		SET_CLIENT_EMPLOYEE_LIST_CURRENT_PAGE(state, payload) {
			return {
				...state,
				employeeData: {
					...state.employeeData,
					currentPage: payload,
					listOfEmployeesForClient: undefined
				}
			}
		},

		SET_CLIENT_EMPLOYEE_LIST_SEARCH(state, payload) {
			return {
				...state,
				employeeData: {
					...state.employeeData,
					searchString: payload,
					currentPage: 1,
					listOfEmployeesForClient: undefined
				}
			}
		},

		SET_CLIENT_EMPLOYEE_LIST_FILTER(state, payload) {
			return {
				...state,
				employeeData: {
					...state.employeeData,
					currentFilter: payload,
					currentPage: 1,
					listOfEmployeesForClient: undefined
				}
			}
		},

		SET_CLIENT_EMPLOYEE_LIST_SORT(state, payload) {
			return {
				...state,
				employeeData: {
					...state.employeeData,
					range: payload?.range,
					sortBy: payload?.sortBy,
					listOfEmployeesForClient: undefined
				}
			}
		},

		SET_CLIENT_EMPLOYEE_LIST_REPORT(state, payload) {
			return {
				...state,
				employeeData: {
					...state.employeeData,
					reportFile: payload
				}
			}
		},

		SET_CLIENT_EMPLOYEE_CLEAR(state) {
			return {
				...state,
				employeeData: {
					...initialState.employeeData
				}
			}
		},

		SET_TWO_FA_LIST(state, payload) {
			return {
				...state,
				twoFAListData: {
					...state.twoFAListData,
					twoFAList: payload?.data,
					perPage: payload?.per_page,
					currentTotal: payload?.total,
					filters: payload?.filters,
				}
			}
		},

		SET_TWO_FA_ENFORCE(state, payload) {
			return {
				...state,
				isEnforcementTwoFAEnabled: payload
			}
		},

		SET_TWO_FA_TABLE_CURRENT_PAGE(state, payload) {
			return {
				...state,
				twoFAListData: {
					...state.twoFAListData,
					currentPage: payload,
					twoFAList: undefined
				}
			}
		},

		SET_TWO_FA_TABLE_SEARCH(state, payload) {
			return {
				...state,
				twoFAListData: {
					...state.twoFAListData,
					searchString: payload,
					currentPage: 1,
					twoFAList: undefined
				}
			}
		},

		SET_TWO_FA_TABLE_FILTER(state, payload) {
			return {
				...state,
				twoFAListData: {
					...state.twoFAListData,
					currentFilter: payload,
					currentPage: 1,
					twoFAList: undefined
				}
			}
		},

		SET_TWO_FA_TABLE_REPORT(state, payload) {
			return {
				...state,
				twoFAListData: {
					...state.twoFAListData,
					reportFile: payload
				}
			}
		},

		SET_TWO_FA_TABLE_CLEAR(state) {
			return {
				...state,
				twoFAListData: {
					...initialState.twoFAListData
				},
				isEnforcementTwoFAEnabled: undefined
			}
		},

		SET_MFA_DATA(state, payload) {
			return {
				...state,
				mfaData: payload
			}
		},

		SET_MASQUERADE_INFO(state, payload) {
			return {
				...state,
				masquerade: {
					...state.masquerade,
					...payload
				}
			}
		},

		SET_WELCOME_CAROUSEL_REQUIRED(state, payload) {
			return {
				...state,
				carouselRequired: payload
			}
		},

		SET_AVATAR_SET_PLAIN_FILE_URL(state, avatarsetPlainFileURL) {
			return {
				...state,
				client: {
					...state.client,
					scheme: {
						...state.client.scheme,
						avatarSet: {
							avatarsetPlainFileURL
						}
					}
				}
			}
		},

		SET_CORE_BRANDING_URLS(state, payload) {
			return {
				...state,
				brandHeaderLogo: payload.brandHeaderLogo,
				brandHeaderLogoMobile: payload.mobileLogo,
				client: {
					...state.client,
					scheme: {
						...state.client.scheme,
						avatarSet: {
							avatarsetPlainFileURL: payload.avatarsetPlainFileURL
						}
					}
				}
			}
		},

		SET_COOKIES_ACCEPTED(state) {
			return {
				...state,
				cookiesAccepted: true,
			}
		},

		SET_NON_NECESSARY_COOKIES(state, payload) {
			return {
				...state,
				nonNecessaryCookiesEnabled: payload.nonNecessaryCookiesEnabled
			}
		},

		SET_LIFE_EVENT_REPORT(state, payload) {
			return {
				...state,
				lifeEventsReportData: payload
			}
		},

		SET_CCV_MANAGE(state, payload) {
			return {
				...state,
				client: {
					...state.client,
					ccv: {
						nextVoucherDate: payload.nextVoucherDate
							? format(fromUnixTime(payload.nextVoucherDate), 'dd.MM.yy')
							: null,
						placedOrders: payload.placedOrders,
						savedOrders: payload.savedOrders
					}
				}
			}
		},

		SET_BENEFIT_TAKEUP_DATA(state, payload) {
			return {
				...state,
				benefitTakeupData: {
					...state.benefitTakeupData,
					summaryData: payload
				}
			}
		},

		SET_TAKEUP_FOR_BENEFIT(state, payload) {
			return {
				...state,
				benefitTakeupData: {
					...state.benefitTakeupData,
					detailedData: payload
				}
			}
		},

		SET_SELECTION_APPROVAL_DATA(state, payload) {
			return {
				...state,
				approvalSelections: {
					...state.approvalSelections,
					count: payload.count,
					summary: payload.summary
				}
			}
		},

		ENHANCED_MTB_OVERVIEW_MODE(state, { mode }) {
			return {
				...state,
				modeMTB: mode
			}
		},

	},

	effects: dispatch => ({

		async LOGIN_URL(payload, state) {
			const response = await fetchApi(restAPI.BEAM_LOGIN_URL_REDIRECT, {
				method: 'POST',
				body: JSON.stringify({ email: payload.email })
			});

			if (response.error) {
				dispatch({
					type: 'user/SET_LOGIN_ERROR',
					payload: 'Unable to process login request'
				});
				return { hasSSO: false, error: true };
			}

			if (response.url) {
				window.location.href = response.url;
				return { hasSSO: true };
			}

			return { hasSSO: false };
		},

		async REQUEST_ACCESS_TOKEN({ email, password, isFromActivation }, state) {

			const { cookiesAccepted } = state.cookie

			if (cookiesAccepted) {

				const deviceToken = getDeviceToken();

				const response = await fetchApi(restAPI.BEAM_LOGIN, {
					method: 'POST',
					body: JSON.stringify({ email, password, deviceToken })
				});

				const {
					access_token,
					showRecaptcha,
					userAccountLocked,
					error_message,
					is_should_setup_2fa,
					is_should_validate_2fa,
					secret,
					url
				} = response

				if (is_should_setup_2fa) {
					this.SET_MFA_DATA({ secret, url })
					dispatch({
						type: "user/SET_THEME",
						payload: response.scheme
					})

					window.localStorage.setItem('login', email);
					window.localStorage.setItem('password', password);

					if (isFromActivation) {
						await navigate('/two-fa-qr-setup', { state: { isFromActivation } })
					} else {
						await navigate('/two-fa-qr-setup')
					}
				}

				if (is_should_validate_2fa) {
					window.localStorage.setItem('login', email);
					window.localStorage.setItem('password', password);

					await navigate('/two-fa')
				}

				if (access_token) {
					await dispatch({
						type: 'user/REQUEST_LOGIN',
						payload: { email, password, tokenInformation: { ...response } }
					})
				} else if (!access_token && !is_should_setup_2fa && !is_should_validate_2fa) {
					let payload_message = userAccountLocked ? m.ACCOUNT_LOCKED : m.STANDARD_LOGIN_ERROR;
					if (error_message) {
						payload_message = error_message;
					}

					dispatch({
						type: 'user/SET_LOGIN_ERROR',
						payload: payload_message
					})
				}

				if (showRecaptcha) {
					return { showRecaptcha: true }
				}

			} else {
				dispatch({
					type: 'user/SET_LOGIN_ERROR',
					payload: 'Please accept cookies before logging in'
				})
			}

		},

		async WRKIT_RE_DIRECT(state) {
			const response = await fetchApi(restAPI.WRKIT_SSO, {
				method: 'GET',
			});

			const {
				redirect,
			} = response;

			if (redirect) {
				window.location.href = redirect;
				return;
			}
		},

		async PERSIST_LOGIN(state) {
			const loginCredentials = window.localStorage.getItem("loginCredentials");
			if (loginCredentials) {
				const passwordCredentials = JSON.parse(loginCredentials);

				dispatch({
					type: "user/SET_LOGIN_CREDENTIALS",
					payload: passwordCredentials,
				});
			}
			const {
				success: loginSetupSuccess,
				coreTheme,
				schemeId,
				avatarsetPlainFileURL
			} = await dispatch({
				type: 'user/REQUEST_LOGIN_SETUP',
			})

			if (loginSetupSuccess) {
				if (coreTheme) {
					dispatch({
						type: "user/REQUEST_V2_CORE_BRANDING_URLS",
						payload: { schemeId }
					})
				} else if (!avatarsetPlainFileURL) {
					dispatch({
						type: "user/REQUEST_V2_AVATAR_SET",
						payload: { schemeId }
					})
				}

				dispatch({
					type: 'benefits/REQUEST_BENEFITS'
				})

				dispatch({
					type: 'user/SET_LOGIN',
				})
			} else {
				dispatch({
					type: 'user/SET_LOGIN_ERROR',
					payload: m.STANDARD_LOGIN_ERROR
				})
			}
		},

		async REQUEST_LOGIN({ email, password, sso, tokenInformation }, state) {
			dispatch({
				type: 'user/SET_LOGIN_CREDENTIALS',
				payload: tokenInformation
			})

			const {
				success: loginSetupSuccess,
				coreTheme,
				schemeId,
				avatarsetPlainFileURL
			} = await dispatch({
				type: 'user/REQUEST_LOGIN_SETUP',
			})


			let cookiesAccepted = false;

			if (sso || !R.isEmpty(state.masquerade)) {
				cookiesAccepted = true;
			} else {
				cookiesAccepted = state.cookie;
			}

			// All the BEAM checks done, now to call legacy

			let v2LoginError = null


			if(cookiesAccepted)
			{
				if(loginSetupSuccess)
				{
					// TODO: Remove when API call for happypeople branding is no longer.
					if (coreTheme) {
						dispatch({
							type: "user/REQUEST_V2_CORE_BRANDING_URLS",
							payload: { schemeId }
						})
					}
					else if (!avatarsetPlainFileURL) {
						dispatch({
							type: "user/REQUEST_V2_AVATAR_SET",
							payload: { schemeId }
						})
					}

					dispatch({
						type: 'benefits/REQUEST_BENEFITS'
					})

					dispatch({
						type: 'user/SET_LOGIN',
					})
				}
				else {
					dispatch({
						type: 'user/SET_LOGIN_ERROR',
						payload: m.STANDARD_LOGIN_ERROR
					})
				}
			} else {
				dispatch({
					type: 'user/SET_LOGIN_ERROR',
					payload: 'Please accept cookies before logging in'
				})
			}


		},

		async REQUEST_SSO_LOGIN({ token, masqueradeSchemeYear }) {
			const response = await fetchApi(restAPI.BEAM_SSO_LOGIN, {
				method: 'POST',
				body: JSON.stringify({ sso: token })
			})

			const { masqueradeUserId, error } = response

			if (masqueradeUserId && masqueradeSchemeYear) {
				await dispatch({
					type: "user/SET_MASQUERADE_INFO",
					payload: {
						userId: masqueradeUserId,
						schemeYear: masqueradeSchemeYear
					}
				})
			}

			if (!error)
				await dispatch({
					type: 'user/REQUEST_LOGIN',
					payload: { sso: true, tokenInformation: { ...response } }
				})
			else
				throw new Error("SSO login error")
		},

		async REQUEST_LOGIN_SETUP() {
			const response = await fetchApi(restAPI.LOGIN_SETUP, {
				method: 'GET'
			})

			const { error } = response;

			if (error)
				return { success: false }

			if (!error) {

				dispatch({
					type: 'user/SET_USER',
					payload: response.user
				})

				dispatch({
					type: 'user/SET_CLIENT',
					payload: response.client
				})

				dispatch({
					type: 'user/SET_CMS_DATA',
					payload: response.cmsData
				})

				dispatch({
					type: "user/SET_THEME",
					payload: response.client.scheme
				})

				const coreTheme = response.client.scheme.theme.core
				const schemeId = response.client.scheme.id

				if (response.user.firstLogin)
					dispatch({
						type: "user/SET_WELCOME_CAROUSEL_REQUIRED",
						payload: true
					})

				return {
					success: true,
					coreTheme,
					schemeId,
					avatarsetPlainFileURL: response.client.scheme.avatarSet.avatarsetPlainFileURL
				}
			}

		},

		// DELETE ME WHEN WE ARE FREE FROM WILB BRANDING!!!
		async REQUEST_V2_CORE_BRANDING_URLS() {
			const response = await (fetchApi(restAPI.GET_V2_CORE_BRANDING_URLS))

			if (!response.error) {

				dispatch({
					type: 'user/SET_CORE_BRANDING_URLS',
					payload: response
				})

				const theme = JSON.parse(localStorage.getItem('client-theme'))

				theme.mobileLogo = response.mobileLogo
				theme.brandHeaderLogo = response.brandHeaderLogo

				localStorage.setItem('client-theme', JSON.stringify(theme))
			}
		},

		async REQUEST_USER() {
			const response = await fetchApi(restAPI.USER);

			const { error } = response;

			if (!error) {

				dispatch({
					type: "user/SET_USER",
					payload: response
				})
			}
		},

		async REQUEST_V2_AVATAR_SET({ schemeId }) {
			const response = await fetchApi(restAPI.V2_AVATAR_SET(schemeId));
			const { avatarsetPlainFileURL, error } = response;

			if (!error) {
				dispatch({
					type: "user/SET_AVATAR_SET_PLAIN_FILE_URL",
					payload: avatarsetPlainFileURL
				})
			}

		},

		async REQUEST_FAMILY() {
			const response = await fetchApi(restAPI.USER_FAMILY);

			const { error } = response;

			if (!error) {

				dispatch({
					type: "user/SET_FAMILY",
					payload: response
				})
			}
		},

		async REQUEST_THEME() {
			const response = await fetchApi(restAPI.SCHEME)
			const { error } = response

			if (!error) {
				dispatch({
					type: 'user/SET_THEME',
					payload: response
				})
			}
		},

		async PATCH_USER(payload) {
			const response = await fetchApi(restAPI.USER_PROFILE, {
				method: 'PATCH',
				body: JSON.stringify(payload)
			})

		},

		async PATCH_TERMS(payload) {

			const response = await fetchApi(restAPI.USER_PATCH_TERMS, {
				method: 'PATCH',
				body: JSON.stringify({ termsAccepted: payload })
			})

			const { error } = response

			if (!error) {
				dispatch({
					type: 'user/SET_TERMS_REVISION',
					payload: { siteTermsUpdated: false, termsRevision: true }
				})
			} else {
				dispatch({
					type: 'user/SET_TERMS_REVISION',
					payload: { siteTermsUpdated: true, termsRevision: false }
				})
			}
		},

		async REQUEST_GET_AVAILABLE_LIFE_EVENTS() {
			const response = await fetchApi(restAPI.GET_AVAILABLE_LIFE_EVENTS)
			const { error } = response

			if (error)
				return false

			dispatch({
				type: "user/SET_AVAILABLE_LIFE_EVENTS",
				payload: response
			})

		},

		async REQUEST_GET_USER_LIFE_EVENTS() {
			const response = await fetchApi(restAPI.GET_USER_LIFE_EVENTS)
			const { error } = response

			if (error)
				return false

			dispatch({
				type: "user/SET_USER_LIFE_EVENTS",
				payload: response
			})
		},

		async REQUEST_ADD_LIFE_EVENT(payload) {
			const response = await fetchApi(restAPI.ADD_LIFE_EVENT, {
				method: "POST",
				body: JSON.stringify(payload)
			})

			const { error } = response

			if (error)
				return false

			dispatch({
				type: 'benefits/SET_REFRESH_REQUIRED',
				payload: { benefitsAndSelections: true }
			})

			dispatch({
				type: "user/SET_USER_LIFE_EVENTS",
				payload: false
			})

			return true
		},

		async REQUEST_ADD_FAMILY_MEMBER({ id, ...values }) {
			const response = await fetchApi(restAPI.ADD_FAMILY(id), {
				method: 'POST',
				body: JSON.stringify({ ...values })
			});

			const { status, error } = response;

			if (error) {
				dispatch({
					type: 'user/ADD_FAMILY_STATUS',
					payload: error
				});
				return;
			}

			if (status === 'ok') {
				dispatch({
					type: 'benefits/SET_REFRESH_REQUIRED',
					payload: { benefitsAndSelections: true }
				});

				const formattedFamilyMember = formatFamilyMemberResponse(response.familyMember);

				dispatch({ type: 'user/ADD_FAMILY_STATUS', payload: 'success' });
				dispatch({
					type: 'user/ADD_FAMILY_MEMBER',
					payload: formattedFamilyMember
				});
			} else {
				dispatch({
					type: 'user/ADD_FAMILY_STATUS',
					payload: 'error'
				});
			}
		},

		async REQUEST_EDIT_FAMILY_MEMBER({ familyMemberId, ...values }) {
			const response = await fetchApi(restAPI.EDIT_FAMILY(familyMemberId), {
				method: 'PATCH',
				body: JSON.stringify({ ...values })
			});

			const { error } = response;

			if (error) {
				dispatch({
					type: 'user/ADD_FAMILY_STATUS',
					payload: error
				});
				return;
			}

			dispatch({
				type: 'benefits/SET_REFRESH_REQUIRED',
				payload: { benefitsAndSelections: true }
			});

			dispatch({ type: 'user/ADD_FAMILY_STATUS', payload: 'success' });

			await dispatch({ type: 'user/REQUEST_FAMILY' });
		},

		async REQUEST_PAYROLL_REPORT(_, state) {
			const {
				user: { client }
			} = state

			const response = await fetchApi(restAPI.PAYROLL(client.id))

			const { error } = response

			if (!error) {

				function sortAndFormatResponse(response) {
					return Object.keys(response).forEach((key) => {
						response[key] = R.compose(
							R.sortBy(item => item.timestamp),
							R.map(item => ({
								...item,
								timestamp: format(new Date(item.year, item.month, 1), 'T')
							}))
						)(response[key])
					})
				}

				sortAndFormatResponse(response);

				dispatch({ type: 'user/SET_PAYROLL_REPORT', payload: response })
			} else {
				dispatch({ type: 'user/SET_PAYROLL_REPORT', payload: 'Error' })
			}
		},

		async REQUEST_PROVIDER_REPORT(_, state) {
			const {
				user: { client }
			} = state

			const response = await fetchApi(restAPI.PROVIDER(client.id))

			const { error } = response

			if (!error) {

				function sortAndFormatResponse(response) {
					return Object.keys(response).forEach((key) => {
						response[key] = R.compose(
							R.sortWith([
								R.ascend(R.prop('timestamp')),
								R.ascend(R.prop('label')),
								R.ascend(R.prop('providerName'))
							]),
							R.map(item => ({
								...item,
								timestamp: format(new Date(item.year, item.month, 1), 'T')
							}))
						)(response[key])
					})
				}

				sortAndFormatResponse(response);

				dispatch({ type: 'user/SET_PROVIDER_REPORT', payload: response })
			} else {
				dispatch({ type: 'user/SET_PROVIDER_REPORT', payload: 'Error' })
			}
		},

		async REQUEST_ACTIVATION(payload) {
			const { email, password, token, isFromActivation } = payload

			const response = await fetchApi(restAPI.ACTIVATE_MAGIC_LINK,
				{
					method: 'PATCH',
					body: JSON.stringify({ sso: token })
				});

			const { error } = response;

			if (!error) {

				await dispatch({
					type: 'user/REQUEST_ACCESS_TOKEN',
					payload: {
						email,
						password,
						isFromActivation
					}
				})

				dispatch({
					type: "user/SET_WELCOME_CAROUSEL_REQUIRED",
					payload: true
				})

			} else {
				await dispatch({
					type: 'user/SET_LOGIN_ERROR',
					payload: m.STANDARD_LOGIN_ERROR
				})
			}

			return error;
		},

		async REQUEST_ACTIVATIONV2(response) {
			const { user, benefits, selections, carouselItems } = response

			await dispatch({
				type: 'user/REQUEST_THEME',
				payload: false
			})

			dispatch({
				type: 'benefits/ADD_BENEFITS',
				payload: benefits
			})

			dispatch({
				type: 'benefits/ADD_SELECTIONS',
				payload: selections
			})

			dispatch({
				type: 'user/SET_LOGIN',
				payload: { ...user, carouselItems, showWelcomeModal: true }
			})
		},

		async REQUEST_CAROUSEL_CONTENT() {
			const response = await fetchApi(restAPI.BEAM_GET_CAROUSEL)

			const { error } = response

			if (!error) {
				await dispatch({
					type: 'user/SET_CAROUSEL_CONTENT',
					payload: response
				})

				dispatch({
					type: "user/SET_WELCOME_CAROUSEL_REQUIRED",
					payload: false
				})
			}
		},



		async REQUEST_THEME_FROM_DOMAIN(domainName) {

			const storage = localStorage.getItem('client-theme')
			if (storage) {
				dispatch({
					type: 'user/SET_THEME',
					payload: JSON.parse(storage)
				})
				return
			}

			const response = await fetchApi(
				restAPI.SCHEME_BRANDING({ mode: 'schemeBranding', domain: domainName })
			)

			const { theme, error } = response

			if (!error && theme) {
				dispatch({
					type: 'user/SET_THEME',
					payload: response
				})
			} else {
				dispatch({
					type: 'user/SET_THEME',
					payload: null
				})
			}
		},

		async CLEAR_TOTAL_BENEFITS_SUMMARY() {
			dispatch({
				type: 'user/SET_TOTAL_BENEFITS_SUMMARY',
				payload: {}
			})
		},

		async REQUEST_TOTAL_BENEFITS_SUMMARY() {
			let beamResponse = await fetchApi(restAPI.BEAM_TOTAL_BENEFITS_CARD)

			if(beamResponse.error)
			{


			const beamResponse = await fetchApi(restAPI.BEAM_TOTAL_BENEFITS_CARD)

			}

			dispatch({
				type: 'user/SET_TOTAL_BENEFITS_SUMMARY',
				payload: beamResponse
			})
		},

		async REQUEST_TOTAL_BENEFITS_DATA() {

			const mtbPageData = await fetchApi(
				restAPI.BEAM_TOTAL_BENEFITS_PAGE
			)

			dispatch({
				type: 'user/SET_MTB_PAGE_DATA',
				payload: mtbPageData
			})

		},

		async REQUEST_ALL_EMPLOYEES_FOR_CLIENT({
			clientId,
			currentPage,
			searchString,
			currentFilter,
			range,
			sortBy
		}) {
			const params = {};
			if (searchString) {
				params.search = searchString;
			}
			if (currentFilter !== null) {
				params["filter[person_activated]"] = currentFilter;
			}
			if (currentPage) {
				params.page = currentPage;
			}
			if (range && sortBy) {
				params.sort_by = sortBy;
				params.range = range;
			}

			const preparedParams = decodeURIComponent(new URLSearchParams(params).toString())
			const path = currentPage || searchString || currentFilter ?
				restAPI.GET_ALL_EMPLOYEES_FOR_CLIENT_PARAMS(clientId, preparedParams)
				: restAPI.GET_ALL_EMPLOYEES_FOR_CLIENT(clientId)

			const response = await fetchApi(path);

			const { error } = response

			if (error)
				return false

			dispatch({
				type: "user/SET_CLIENT_EMPLOYEE_LIST",
				payload: response
			})
		},

		CLIENT_EMPLOYEE_LIST_CURRENT_PAGE(currentPage) {
			this.SET_CLIENT_EMPLOYEE_LIST_CURRENT_PAGE(currentPage)
		},

		CLIENT_EMPLOYEE_LIST_SEARCH(searchString) {
			this.SET_CLIENT_EMPLOYEE_LIST_SEARCH(searchString)
		},

		CLIENT_EMPLOYEE_LIST_FILTER(filter) {
			this.SET_CLIENT_EMPLOYEE_LIST_FILTER(filter)
		},

		CLIENT_EMPLOYEE_LIST_SORT(payload) {
			this.SET_CLIENT_EMPLOYEE_LIST_SORT(payload)
		},

		CLIENT_EMPLOYEE_CLEAR() {
			this.SET_CLIENT_EMPLOYEE_CLEAR()
		},

		async REQUEST_ALL_EMPLOYEES_FOR_CLIENT_REPORT({ searchString, currentFilter }, state) {
			const params = {};
			if (searchString) {
				params.search = searchString;
			}
			if (currentFilter !== null) {
				params["filter[person_activated]"] = currentFilter;
			}

			const clientId = state.user.client.id

			const preparedParams = decodeURIComponent(new URLSearchParams(params).toString())
			const path = searchString || currentFilter !== null ?
				restAPI.GET_EMPLOYEES_FOR_CLIENT_REPORT_PARAMS(clientId, preparedParams)
				: restAPI.GET_EMPLOYEES_FOR_CLIENT_REPORT(clientId)

			const response = await fetchApi(path, { method: 'GET' }, true);
			const blob = await response?.blob()

			const { error } = response

			if (error) {
				console.log('error', error)
				return false
			}

			this.SET_CLIENT_EMPLOYEE_LIST_REPORT(blob)
		},

		async REQUEST_TWO_FA_TABLE({
			searchString,
			currentPage,
			currentFilter
		}, state) {
			const params = {};
			if (searchString) {
				params.search = searchString;
			}
			if (currentFilter !== null) {
				params["filter[person_mfa_secret]"] = currentFilter;
			}
			if (currentPage) {
				params.page = currentPage;
			}

			const clientId = state.user.client.id

			const preparedParams = decodeURIComponent(new URLSearchParams(params).toString())
			const path = searchString || currentPage || currentFilter ?
				restAPI.TWO_FA_TABLE_PARAMS(clientId, preparedParams)
				: restAPI.TWO_FA_TABLE(clientId)

			const response = await fetchApi(path);

			const { error } = response;
			if (error) {
				return false;
			}

			dispatch({
				type: "user/SET_TWO_FA_LIST",
				payload: response,
			});
			dispatch({
				type: "user/SET_TWO_FA_ENFORCE",
				payload: response?.isEnforcementTwoFAEnabled,
			});
		},

		TWO_FA_TABLE_CURRENT_PAGE(currentPage) {
			this.SET_TWO_FA_TABLE_CURRENT_PAGE(currentPage)
		},

		TWO_FA_TABLE_SEARCH(searchString) {
			this.SET_TWO_FA_TABLE_SEARCH(searchString)
		},

		TWO_FA_TABLE_FILTER(filter) {
			this.SET_TWO_FA_TABLE_FILTER(filter)
		},

		TWO_FA_TABLE_CLEAR() {
			this.SET_TWO_FA_TABLE_CLEAR()
		},

		async REQUEST_TWO_FA_TABLE_REPORT({ searchString, currentFilter }, state) {
			const params = {};
			if (searchString) {
				params.search = searchString;
			}
			if (currentFilter !== null) {
				params["filter[person_mfa_secret]"] = currentFilter;
			}
			params.type = 'employeeTwoFA'

			const clientId = state.user.client.id

			const preparedParams = decodeURIComponent(new URLSearchParams(params).toString())
			const path = restAPI.GET_EMPLOYEES_FOR_CLIENT_REPORT_PARAMS(clientId, preparedParams)

			const response = await fetchApi(path, { method: 'GET' }, true);
			const blob = await response?.blob()

			const { error } = response

			if (error) {
				console.log('error', error)
				return false
			}

			this.SET_TWO_FA_TABLE_REPORT(blob)
		},

		async REQUEST_MAGIC_LINK_FOR_EMPLOYER({ password, schemeId, employeeId }) {
			return fetchApi(restAPI.GET_MAGIC_LINK_EMPLOYER_MASQUERADE(schemeId, employeeId), {
				method: 'POST',
				body: JSON.stringify({ password })
			});
		},

		async ACCEPT_COOKIES(_, state) {
			const { loginError } = state.user

			if (!!loginError) {
				await dispatch({
					type: 'user/SET_LOGIN_ERROR',
					payload: false
				})
			}

			dispatch({
				type: 'user/SET_COOKIES_ACCEPTED'
			})
		},

		async REQUEST_LIFE_EVENT_REPORT(_, state) {
			const {
				user: { client }
			} = state

			const response = await fetchApi(restAPI.GET_LIFE_EVENT_REPORT(client.id))

			const { error } = response

			if (!error) {
				const data = R.compose(
					R.map(item => ({
						...item,
						closed_year: parseInt(format(fromUnixTime(item.closed), 'yyyy'))
					})) // returns an array
				)(response)

				dispatch({ type: 'user/SET_LIFE_EVENT_REPORT', payload: data })
			} else {
				dispatch({ type: 'user/SET_LIFE_EVENT_REPORT', payload: 'Error' })
			}
		},

		async REQUEST_CCV_MANAGE() {
			const response = await fetchApi(restAPI.GET_CCV_MANAGE)
			const { error } = response

			if (!error) {
				dispatch({
					type: 'user/SET_CCV_MANAGE',
					payload: response
				})
			}
		},

		async REQUEST_BENEFIT_TAKEUP_DATA(_, state) {
			const {
				user: { client, isInsightsEnabled }
			} = state

			const endpoint = isInsightsEnabled
				? restAPI.GET_BENEFIT_INSIGHTS_DATA(client.id)
				: restAPI.GET_BENEFIT_TAKEUP_DATA(client.id)

			const response = await fetchApi(endpoint)
			const { error } = response

			if (!error) {
				dispatch({ type: 'user/SET_BENEFIT_TAKEUP_DATA', payload: response })
			} else {
				dispatch({ type: 'user/SET_BENEFIT_TAKEUP_DATA', payload: 'Error' })
			}
		},

		async GET_TAKEUP_FOR_BENEFIT({ clientId, benefitId }) {
			const response = await fetchApi(restAPI.GET_TAKEUP_FOR_BENEFIT(clientId, benefitId))
			const { error } = response

			let data = response
			data.benefitId = benefitId;

			if (!error) {
				dispatch({ type: 'user/SET_TAKEUP_FOR_BENEFIT', payload: data })
			} else {
				dispatch({ type: 'user/SET_TAKEUP_FOR_BENEFIT', payload: 'Error' })
			}
		},

		async GET_SELECTIONS_FOR_APPROVAL(_, state) {
			const {
				user: { client }
			} = state

			const response = await fetchApi(restAPI.GET_SELECTIONS_FOR_APPROVAL)
			const { error } = response

			if (!error) {
				dispatch({ type: 'user/SET_SELECTION_APPROVAL_DATA', payload: response })
			} else {
				dispatch({ type: 'user/SET_SELECTION_APPROVAL_DATA', payload: 'Error' })
			}
		},

		SET_ENHANCED_MTB_OVERVIEW_MODE({ mode }) {
			this.ENHANCED_MTB_OVERVIEW_MODE({ mode })
		},

	})
}
